var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-lg-5 position-fixed"},[_vm._v(" Membership ")])]),_c('b-col',{staticClass:"p-lg-5",attrs:{"lg":"6","md":"12"}},[_c('div',{staticClass:"brand-img"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-lg-2 px-md-0 px-sm-0 p-lg-5 p-md-0 p-sm-0",attrs:{"lg":"6","md":"12"}},[_c('b-col',{staticClass:"px-xl-2 px-md-0 px-sm-0 mx-lg-auto mx-md-2 mx-sm-1 mt-md-3",attrs:{"sm":"8","md":"11","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_c('div',{staticClass:"d-lg-flex d-md-flex justify-content-between card-header-section"},[_c('span',[_vm._v("PAVRISK Membership")]),_c('span',{staticClass:"font-small-3"},[_vm._v("Step "+_vm._s(_vm.current_page)+" of "+_vm._s(_vm.total_pages))])])]),_c('validation-observer',{ref:"registerFirstForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.current_page === 1)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationFirstForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Type of Content","label-for":"content"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.content_types,"size":"md"},on:{"change":_vm.updateContentTypeSelection},model:{value:(_vm.content_type),callback:function ($$v) {_vm.content_type=$$v},expression:"content_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Your Role","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.content_roles,"size":"md"},on:{"change":_vm.updateContentRoleSelection},model:{value:(_vm.member_role_type),callback:function ($$v) {_vm.member_role_type=$$v},expression:"member_role_type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"d-flex flex-column"},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" I agree to "),_c('b-link',[_vm._v("privacy policy & terms")])],1)],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Continue" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1):_vm._e()]}}])}),_c('validation-observer',{ref:"registerSecondForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.current_page === 2)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationSecondForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('h5',[_vm._v(" Selected Type of Content: ")]),_c('h4',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.selected_content_type)+" ")])]),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('h5',[_vm._v(" Selected Content Role: ")]),_c('h4',{staticClass:"text-primary"},[_vm._v(" "+_vm._s(_vm.selected_content_role)+" ")])])],1),_c('b-row',[_c('b-col',{attrs:{"lg":"12","md":"12","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Select Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.member_types,"size":"md"},on:{"change":_vm.updateMemberTypeSelection},model:{value:(_vm.member_type),callback:function ($$v) {_vm.member_type=$$v},expression:"member_type"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Guardian\'s Surname'
                      : 'Surname',"label-for":"surname"}},[_c('validation-provider',{attrs:{"name":"Surname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"surname","state":errors.length > 0 ? false : null,"name":"register-surnname","placeholder":"Doe","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Guardian\'s Other Names'
                      : 'Other Names',"label-for":"othernames"}},[_c('validation-provider',{attrs:{"name":"Other Names","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"other_names","state":errors.length > 0 ? false : null,"name":"Other Names","placeholder":"John","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.other_names),callback:function ($$v) {_vm.other_names=$$v},expression:"other_names"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-col',{staticClass:"mb-1",attrs:{"lg":"12","sm":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"4","sm":"12"}},[_vm._v(" Select Gender: ")]),_c('b-col',{attrs:{"lg":"8","sm":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Gender","rules":"required","vid":"gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},_vm._l((_vm.gender_options),function(gender){return _c('b-form-radio',{key:gender,staticClass:"mx-2",attrs:{"plain":"","value":gender},model:{value:(_vm.member_gender),callback:function ($$v) {_vm.member_gender=$$v},expression:"member_gender"}},[_vm._v(" "+_vm._s(gender)+" ")])}),1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1):_vm._e(),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-form-group',{attrs:{"label":"Business Name","label-for":"businessname"}},[_c('validation-provider',{attrs:{"name":"Business Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business-name","state":errors.length > 0 ? false : null,"name":"business-name","placeholder":"Enter Business Name"},model:{value:(_vm.business_name),callback:function ($$v) {_vm.business_name=$$v},expression:"business_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Guardian\'s ID No. or Passport No.'
                      : 'ID Number or Passport Number',"label-for":"id_number"}},[_c('validation-provider',{attrs:{"name":"ID Number","rules":"required|validate-id-number","vid":"id_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"id_number","state":errors.length > 0 ? false : null,"name":"ID Number","placeholder":"*********","type":"number"},model:{value:(_vm.id_number),callback:function ($$v) {_vm.id_number=$$v},expression:"id_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-form-group',{attrs:{"label":"Business Registration Number","label-for":"business_registration_number"}},[_c('validation-provider',{attrs:{"name":"Business Registration Number","rules":"required","vid":"business_registration_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_registration_number","state":errors.length > 0 ? false : null,"name":"business-registration-number","placeholder":"Enter Business Registration Number"},model:{value:(_vm.business_registration_number),callback:function ($$v) {_vm.business_registration_number=$$v},expression:"business_registration_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(
                    _vm.member_type_in_words === 'Adult' ||
                      _vm.member_type_in_words === 'Deceased Person' ||
                      _vm.member_type_in_words === ''
                  )?_c('b-form-group',{attrs:{"label":"KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required|validate-kra-pin","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"kra_pin","state":errors.length > 0 ? false : null,"name":"kra-pin","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-form-group',{attrs:{"label":"Business's KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required|validate-kra-pin","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_kra_pin","state":errors.length > 0 ? false : null,"name":"kra-pin","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type_in_words === 'Minor')?_c('b-form-group',{attrs:{"label":"Guardian's KRA PIN","label-for":"kra_pin"}},[_c('validation-provider',{attrs:{"name":"KRA PIN","rules":"required|validate-kra-pin","vid":"kra_pin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"guardian_kra_pin","state":errors.length > 0 ? false : null,"name":"kra-pin","placeholder":"*********"},model:{value:(_vm.kra_pin),callback:function ($$v) {_vm.kra_pin=$$v},expression:"kra_pin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(
                    _vm.member_type_in_words === 'Adult' ||
                      _vm.member_type_in_words === 'Minor' ||
                      _vm.member_type_in_words === 'Deceased Person' ||
                      _vm.member_type_in_words === ''
                  )?_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Guardian\'s Email'
                      : 'Email',"label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"adult_email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-form-group',{attrs:{"label":"Business Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_email","state":errors.length > 0 ? false : null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(
                    _vm.member_type_in_words === 'Adult' ||
                      _vm.member_type_in_words === 'Minor' ||
                      _vm.member_type_in_words === 'Deceased Person' ||
                      _vm.member_type_in_words === ''
                  )?_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Guardian\'s Registered Phone Number'
                      : 'Your Registered Phone Number',"label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"phone_number","rules":"required","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"adult_phone_number","state":errors.length > 0 ? false : null,"name":"phone-number","placeholder":"07000000","type":"number"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-form-group',{attrs:{"label":"Business Phone Number","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"phone_number","rules":"required","vid":"phone_number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"business_phone_number","state":errors.length > 0 ? false : null,"name":"phone-number","placeholder":"07000000","type":"number"},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.goToPrevPage}},[_vm._v(" Previous ")]),(_vm.total_pages === 4 || _vm.total_pages === 3)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Continue" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e(),(_vm.total_pages === 2)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Sign Up" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e()],1)],1):_vm._e()]}}])}),_c('validation-observer',{ref:"registerThirdForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var invalid = ref.invalid;
return [(_vm.current_page === 3)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationThirdForm.apply(null, arguments)}}},[_c('b-row',[(_vm.member_type_in_words === 'Minor')?_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(_vm.member_type_in_words === 'Minor')?_c('b-form-group',{attrs:{"label":"Minor's Name","label-for":"child_name"}},[_c('validation-provider',{attrs:{"name":"Minor's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_name","state":errors.length > 0 ? false : null,"name":"Minor's Name","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.child_name),callback:function ($$v) {_vm.child_name=$$v},expression:"child_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words === 'Minor')?_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(_vm.member_type_in_words === 'Minor')?_c('b-form-group',{attrs:{"label":"Minor's Birth Certificate","label-for":"child_birth_certificate"}},[_c('validation-provider',{attrs:{"name":"Minor's Birth Certificate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"id":"child_birth_certificate","state":errors.length > 0 ? false : null,"name":"Minor's Birth Certificate","accept":".pdf"},model:{value:(_vm.child_birth_certificate),callback:function ($$v) {_vm.child_birth_certificate=$$v},expression:"child_birth_certificate"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.child_birth_certificate ? _vm.child_birth_certificate.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_child_certificate_too_large_error),expression:"show_child_certificate_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words === 'Minor')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words === 'Minor')?_c('b-form-group',{attrs:{"label":"Minor's Age","label-for":"age"}},[_c('validation-provider',{attrs:{"name":"Age","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-spinbutton',{attrs:{"id":"age","min":"1","max":"18"},model:{value:(_vm.age),callback:function ($$v) {_vm.age=$$v},expression:"age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words === 'Minor')?_c('b-col',{attrs:{"lg":"6","sm":"12"}},[(_vm.member_type_in_words === 'Minor')?_c('b-form-group',{attrs:{"label":"Enter Relationship To Minor","label-for":"child_relationship"}},[_c('validation-provider',{attrs:{"name":"Minor Guardian Relationship","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"child_relationship","placeholder":"e.g Mother, Father, Brother, Cousin","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.child_relationship),callback:function ($$v) {_vm.child_relationship=$$v},expression:"child_relationship"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),(_vm.member_type_in_words !== 'Corporate/Record Label' && _vm.member_type_in_words !== 'Company')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Upload Guardian\'s ID or Passport'
                      : 'Upload Your ID or Passport',"label-for":"id_upload"}},[_c('validation-provider',{attrs:{"name":"National ID or Passport","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"identification_document","state":errors.length > 0 ? false : null,"name":"Identification Document","accept":".pdf"},model:{value:(_vm.id_file),callback:function ($$v) {_vm.id_file=$$v},expression:"id_file"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.id_file ? _vm.id_file.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_id_file_too_large_error),expression:"show_id_file_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.member_type_in_words === 'Minor'
                      ? 'Upload Guardian\'s KRA PIN Certificate'
                      : 'Upload KRA PIN Certificate',"label-for":"kra_certificate"}},[_c('validation-provider',{attrs:{"name":"KRA Certificate","rules":"required","vid":"kra_cerfificate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"kra_certificate","state":errors.length > 0 ? false : null,"name":"kra_certificate","accept":".pdf"},model:{value:(_vm.kra_certificate),callback:function ($$v) {_vm.kra_certificate=$$v},expression:"kra_certificate"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.kra_certificate ? _vm.kra_certificate.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_kra_certificate_too_large_error),expression:"show_kra_certificate_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1),(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Upload Business Permit","label-for":"business_registration_certificate"}},[_c('validation-provider',{attrs:{"name":"Business Permit","rules":"required","vid":"business_registration_certificate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"business_registration_certificate","state":errors.length > 0 ? false : null,"name":"business_registration_certificate","accept":".pdf"},model:{value:(_vm.business_registration_certificate),callback:function ($$v) {_vm.business_registration_certificate=$$v},expression:"business_registration_certificate"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.business_registration_certificate ? _vm.business_registration_certificate.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_business_registration_certificate_too_large_error),expression:"show_business_registration_certificate_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),(_vm.member_type_in_words === 'Deceased Person')?_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(_vm.member_type_in_words === 'Deceased Person')?_c('b-form-group',{attrs:{"label":"Enter Deceased Person's Name","label-for":"deceased_person_name"}},[_c('validation-provider',{attrs:{"name":"Deceased Person's Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"deceased_person_name","type":"text","onkeydown":"return /[a-z]/i.test(event.key)"},model:{value:(_vm.deceased_person_name),callback:function ($$v) {_vm.deceased_person_name=$$v},expression:"deceased_person_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.labels.passport_photo,"label-for":"avatar"}},[_c('validation-provider',{attrs:{"name":"avatar","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"avatar","state":errors.length > 0 ? false : null,"name":"avatar","accept":".jpg,.jpeg,.png"},model:{value:(_vm.avatar),callback:function ($$v) {_vm.avatar=$$v},expression:"avatar"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.avatar ? _vm.avatar.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[(
                    _vm.show_stage_name_input &&
                      (_vm.member_type_in_words === 'Adult' ||
                        _vm.member_type_in_words === 'Minor' ||
                        _vm.member_type_in_words === '')
                  )?_c('b-form-group',{attrs:{"label":_vm.labels.stage_name,"label-for":"stage_name"}},[_c('validation-provider',{attrs:{"name":"Stage Name","rules":"required","vid":"stage_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"stage_name","state":errors.length > 0 ? false : null,"name":"stage-name"},model:{value:(_vm.stage_name),callback:function ($$v) {_vm.stage_name=$$v},expression:"stage_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e()],1),(_vm.member_type_in_words === 'Deceased Person')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Deceased Person's Death Certificate","label-for":"death_certificate"}},[_c('validation-provider',{attrs:{"name":"death certificate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"death_certificate","state":errors.length > 0 ? false : null,"name":"death certificate","accept":".pdf"},model:{value:(_vm.deceased_death_certificate),callback:function ($$v) {_vm.deceased_death_certificate=$$v},expression:"deceased_death_certificate"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.deceased_death_certificate ? _vm.deceased_death_certificate.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_death_certificate_too_large_error),expression:"show_death_certificate_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),(_vm.member_type_in_words === 'Deceased Person')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Letter of Administration","label-for":"letter_of_administration"}},[_c('validation-provider',{attrs:{"name":"letter of administration","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"letter_of_administration","state":errors.length > 0 ? false : null,"name":"letter of administration","accept":".pdf"},model:{value:(_vm.deceased_letter_of_administration),callback:function ($$v) {_vm.deceased_letter_of_administration=$$v},expression:"deceased_letter_of_administration"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.deceased_letter_of_administration ? _vm.deceased_letter_of_administration.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_letter_of_administration_too_large_error),expression:"show_letter_of_administration_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),(_vm.member_type_in_words === 'Deceased Person')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Succession Letter","label-for":"succession_letter"}},[_c('validation-provider',{attrs:{"name":"Succession Letter","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"succession_letter","state":errors.length > 0 ? false : null,"name":"succession letter","accept":".pdf"},model:{value:(_vm.deceased_succession_letter),callback:function ($$v) {_vm.deceased_succession_letter=$$v},expression:"deceased_succession_letter"}}),_c('div',{staticClass:"mt-1"},[_vm._v(" Selected file: "),_c('strong',[_vm._v(_vm._s(_vm.deceased_succession_letter ? _vm.deceased_succession_letter.name : ""))])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_succession_letter_too_large_error),expression:"show_succession_letter_too_large_error"}],staticClass:"text-danger"},[_vm._v("File Too Large (Max 5MB)")])]}}],null,true)})],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Password","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Confirm Password","label-for":"password_confirmation"}},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password_confirmation","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password_confirmation),callback:function ($$v) {_vm.password_confirmation=$$v},expression:"password_confirmation"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.goToPrevPage}},[_vm._v(" Previous ")]),(_vm.total_pages === 4)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Continue" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e(),(_vm.total_pages === 3)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Sign Up" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1):_vm._e()],1)],1):_vm._e()]}}])}),_c('validation-observer',{attrs:{"refs":"validateFourthForm"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var invalid = ref.invalid;
return [(_vm.current_page === 4)?_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[(_vm.member_type_in_words === 'Corporate/Record Label' || _vm.member_type_in_words === 'Company')?_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('h5',[_vm._v("Director Details")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-button',{staticClass:"float-right",attrs:{"size":"sm"},on:{"click":_vm.addDirector}},[_vm._v(" Add Director ")])],1)],1),_vm._l((_vm.partners_counter),function(counter){return _c('b-row',{key:counter},[_c('b-col',{attrs:{"cols":"12"}},[_c('h6',[_vm._v(_vm._s(counter)+_vm._s(_vm.nth(counter))+" Director")])]),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Surname","label-for":counter + '-first-name'}},[_c('validation-provider',{attrs:{"name":counter + '' + _vm.nth(counter) + ' Director\'s Surname',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-first-name',"state":errors.length > 0 ? false : null,"type":"text","onkeydown":"return /[a-z]/i.test(event.key)","name":counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s First Name',"placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s First Name'},model:{value:(_vm.partners_first_name[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_first_name, counter - 1, $$v)},expression:"partners_first_name[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Other Names","label-for":counter + '-last-name'}},[_c('validation-provider',{attrs:{"name":counter +
                            '' +
                            _vm.nth(counter) +
                            ' Director\'s Other Names',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-last-name',"state":errors.length > 0 ? false : null,"name":counter + '-director-last-name',"type":"text","onkeydown":"return /[a-z]/i.test(event.key)","placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s  Other Names'},model:{value:(_vm.partners_last_name[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_last_name, counter - 1, $$v)},expression:"partners_last_name[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"ID Number","label-for":counter + '-id-number'}},[_c('validation-provider',{attrs:{"name":counter +
                            '' +
                            _vm.nth(counter) +
                            ' Director\'s ID Number',"rules":"required|validate-id-number"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-id-number',"state":errors.length > 0 ? false : null,"type":"number","name":counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s ID Number',"placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s ID Number'},model:{value:(_vm.partners_id_number[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_id_number, counter - 1, $$v)},expression:"partners_id_number[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":counter + '-phone-number'}},[_c('validation-provider',{attrs:{"name":counter +
                            '' +
                            _vm.nth(counter) +
                            ' Director\'s Phone Number',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-phone-number',"state":errors.length > 0 ? false : null,"type":"number","name":counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s Phone Number',"placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director Phone Number'},model:{value:(_vm.partners_phone_number[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_phone_number, counter - 1, $$v)},expression:"partners_phone_number[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":counter + '-email'}},[_c('validation-provider',{attrs:{"name":counter + '' + _vm.nth(counter) + 'Director\'s Email',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-email',"state":errors.length > 0 ? false : null,"type":"email","name":counter + '' + _vm.nth(counter) + ' Director\'s Email',"placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s Email'},model:{value:(_vm.partners_email[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_email, counter - 1, $$v)},expression:"partners_email[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"lg":"6","sm":"12"}},[_c('b-form-group',{attrs:{"label":"Shares Held","label-for":counter + '-shares'}},[_c('validation-provider',{attrs:{"name":counter + '' + _vm.nth(counter) + ' Director\'s Shares',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":counter + '-director-shares',"state":errors.length > 0 ? false : null,"name":counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s Shares',"placeholder":'Enter ' +
                              counter +
                              '' +
                              _vm.nth(counter) +
                              ' Director\'s Shares'},model:{value:(_vm.partners_shares[counter - 1]),callback:function ($$v) {_vm.$set(_vm.partners_shares, counter - 1, $$v)},expression:"partners_shares[counter - 1]"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)})],2):_vm._e()],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":_vm.goToPrevPage}},[_vm._v(" Previous ")]),_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.isLoading}},[_vm._v(" "+_vm._s(!_vm.isLoading ? "Sign Up" : "Please wait...")+" "),(_vm.isLoading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e()],1)],1)],1):_vm._e()]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-login' }}},[_c('span',[_vm._v("Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }